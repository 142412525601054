import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { Helmet } from 'react-helmet';

import { Dropdown, Menu, Icon, Popup } from 'semantic-ui-react';

import { selectors, actions } from '../modules';
import { connect } from 'react-redux';
import ToJS from './ToJS';

import Moment from 'react-moment';

class ContainerMenu extends Component {
  render() {
    const {
      saveCircuit,
      logOut,
      logIn,
      authenticated,
      isQUILoading,
      circuit,
      circuitSaved,
      circuitSaving,
      circuitLoading,
      circuitError,
      circuitWriteAllowed,
      openModal
    } = this.props;

    const isQUIActive = !isQUILoading;

    const title =
      (circuit && circuit.name) ||
      (!isQUILoading && 'Untitled circuit') ||
      undefined;

    return (
      <Fragment>
        <Helmet>
          <title>{title ? `QUI | ${title}` : 'QUI'}</title>
        </Helmet>

        <div>
          <Menu attached="top" borderless compact inverted>
            <Menu.Menu position="left" style={{ width: '200px' }}>
              <Menu.Item
                as="a"
                href={process.env.REACT_APP_LANDER_URL}
                icon="angle left"
                title="Back to landing page"
              />
              <Dropdown item text="File">
                <Dropdown.Menu style={{ width: '250px' }}>
                  <Dropdown.Item
                    as={Link}
                    to="/"
                    icon="file outline"
                    text="New"
                  />
                  <Dropdown.Item
                    icon="folder open outline"
                    text="Load..."
                    description="ctrl + o"
                    onClick={() => openModal({ modal: 'load' })}
                  />
                  <Dropdown.Item
                    disabled={
                      !isQUIActive ||
                      circuitSaved ||
                      !authenticated ||
                      !circuitWriteAllowed
                    }
                    icon="save"
                    text="Save"
                    description="ctrl + s"
                    onClick={() => saveCircuit()}
                  />
                  <Dropdown.Item
                    disabled={!isQUIActive || !authenticated}
                    icon="save"
                    text="Save as..."
                    description="shift + ctrl + s"
                    onClick={() => openModal({ modal: 'save' })}
                  />
                  <Dropdown.Divider />
                  <Dropdown.Item
                    disabled={!isQUIActive || !authenticated}
                    icon="share alternate"
                    text="Share..."
                    onClick={() => openModal({ modal: 'share' })}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>

            <Menu.Item position="right" header>
              {title || 'QUI'}
            </Menu.Item>

            <Menu.Menu position="right">
              <Menu.Item>
                <div style={{ width: '200px', textAlign: 'right' }}>
                  {circuitError ? (
                    <span>
                      <Popup
                        trigger={<Icon name="exclamation triangle" />}
                        content={circuitError.details}
                        position="left center"
                        size="mini"
                      />
                      {circuitError.message}
                    </span>
                  ) : !authenticated ? (
                    <span>
                      <Popup
                        trigger={<Icon name="info circle" />}
                        content="Please log in to save circuits."
                        position="left center"
                        size="mini"
                      />
                      Guest mode
                    </span>
                  ) : circuitLoading || !isQUIActive ? (
                    <span></span>
                  ) : circuitSaving ? (
                    <span>Saving...</span>
                  ) : circuit ? (
                    circuitWriteAllowed ? (
                      <span>
                        {circuitSaved ? (
                          <Icon name="checkmark" />
                        ) : (
                          <Popup
                            trigger={<Icon name="exclamation triangle" />}
                            content="Your circuit has unsaved changes."
                            position="left center"
                            size="mini"
                          />
                        )}
                        Last saved{' '}
                        {<Moment fromNow>{circuit.updatedAt}</Moment>}
                      </span>
                    ) : (
                      <span>
                        <Popup
                          trigger={<Icon name="info circle" />}
                          content="You do not own this circuit."
                          position="left center"
                          size="mini"
                        />
                        Read-only circuit
                      </span>
                    )
                  ) : (
                    <span>
                      <Icon name="exclamation triangle" />
                      Circuit not saved
                    </span>
                  )}
                  <span />
                </div>
              </Menu.Item>
              {authenticated ? (
                <Dropdown icon="setting" item title="Settings">
                  <Dropdown.Menu>
                    <Dropdown.Item
                      as={Link}
                      icon="user"
                      text="Profile settings"
                      title="Profile settings"
                      to="/settings"
                    />
                    <Dropdown.Item
                      icon="sign out"
                      onClick={logOut}
                      text="Log out"
                      title="Log out"
                    />
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <Menu.Item onClick={logIn}>
                  <Icon name="user" />
                  Log In
                </Menu.Item>
              )}
            </Menu.Menu>
          </Menu>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  authenticated: selectors.authenticated(state),
  isQUILoading: selectors.qui.isQUILoading(state),
  circuit: selectors.qui.circuit(state),
  circuitSaved: selectors.qui.circuitSaved(state),
  circuitSaving: selectors.qui.circuitSaving(state),
  circuitLoading: selectors.qui.circuitLoading(state),
  circuitError: selectors.qui.circuitError(state),
  circuitWriteAllowed: selectors.qui.circuitWriteAllowed(state)
});

const mapDispatchToProps = {
  openModal: actions.qui.openModal,
  saveCircuit: actions.qui.saveCircuit,
  logOut: actions.auth.logOut,
  logIn: actions.auth.logIn
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ToJS(ContainerMenu))
);
