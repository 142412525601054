import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/App';
import Root from './Root';
import history from './modules/history';
import store from './modules/store';

import './index.css';

ReactDOM.render(
  <Root app={App} history={history} store={store} />,
  document.getElementById('root')
);
