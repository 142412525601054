import feathers from '@feathersjs/client';

const restClient = feathers.rest(process.env.REACT_APP_FEATHERS_DOMAIN);

const app = feathers();
app.configure(restClient.fetch(window.fetch));
app.configure(feathers.authentication({ storage: window.localStorage }));

const friendlyErrorMsg = ({ code }, resourceType = 'resource') => {
  if (code === 400) {
    return 'The request was invalid.';
  } else if (code === 401) {
    return 'You are not authenticated.';
  } else if (code === 403) {
    return `You are not allowed to access this ${resourceType}.`;
  } else if (code === 404) {
    return `The ${resourceType} could not be found.`;
  } else if (code === 500) {
    return 'Something went wrong on the server.';
  } else {
    return 'Failed to connect to the server.';
  }
};

export { app as feathersClient, friendlyErrorMsg };
