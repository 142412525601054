import { call, cps, put } from 'redux-saga/effects';

import { actions } from '../..';
import { auth0 } from '../utils';

import setFromLocalStorageSaga from './setFromLocalStorage';

export default function*({ hash }) {
  try {
    const { accessToken, expiresIn: sExpiresIn } = yield cps(auth0.parseHash, {
      hash
    });
    const expiresIn = 1000 * sExpiresIn;

    yield put(actions.auth.setAccessToken({ accessToken, expiresIn }));

    return expiresIn;
  } catch (err) {
    return yield call(setFromLocalStorageSaga);
  }
}
