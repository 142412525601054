import React from 'react';

import { Helmet } from 'react-helmet';
import { Container, Header, Image, Segment } from 'semantic-ui-react';
import { ReactComponent as Ket404 } from './ket404.svg';

export default () => (
  <Segment basic textAlign="center">
    <Helmet>
      <title>QUI | Page not found</title>
    </Helmet>

    <Container text>
      <Header as="h1">The result of your measurement is</Header>
      <Image as={Ket404} centered size="medium" />
      <p>Page not found</p>
    </Container>
  </Segment>
);
