import React, { Component } from 'react';

import { Helmet } from 'react-helmet';
import { Container, Form, Header, Message, Segment } from 'semantic-ui-react';
import Eula from './Eula';

import { reduxForm, Field, SubmissionError } from 'redux-form/immutable';

import { actions, selectors } from '../modules';
import { connect } from 'react-redux';
import ToJS from './ToJS';

const useCases = [
  { key: 'per', value: 'per', text: 'Personal', icon: 'user' },
  { key: 'edu', value: 'edu', text: 'Educational', icon: 'book' },
  { key: 'res', value: 'res', text: 'Research', icon: 'flask' },
  { key: 'misc', value: 'misc', text: 'Other', icon: 'question' }
];

const educationLevels = [
  { key: 'k12', value: 'k12', text: 'K–12', icon: 'paper plane' },
  { key: 'ug', value: 'ug', text: 'Undergraduate', icon: 'plane' },
  { key: 'grad', value: 'grad', text: 'Graduate', icon: 'rocket' }
];

const sectors = [
  {
    key: 'ai',
    value: 'ai',
    text: 'Artificial intelligence',
    icon: 'sitemap'
  },
  {
    key: 'chem',
    value: 'chem',
    text: 'Chemicals and materials',
    icon: 'eye dropper'
  },
  {
    key: 'edu',
    value: 'edu',
    text: 'Education',
    icon: 'graduation'
  },
  {
    key: 'utils',
    value: 'utils',
    text: 'Energy, utilities and resources',
    icon: 'lightning'
  },
  {
    key: 'finance',
    value: 'finance',
    text: 'Financial services',
    icon: 'dollar'
  },
  {
    key: 'health',
    value: 'health',
    text: 'Healthcare',
    icon: 'hospital'
  },
  {
    key: 'industry',
    value: 'industry',
    text: 'Industrial manufacturing',
    icon: 'industry'
  },
  {
    key: 'it',
    value: 'it',
    text: 'Information technology',
    icon: 'server'
  },
  {
    key: 'pharma',
    value: 'pharma',
    text: 'Pharmaceuticals',
    icon: 'medkit'
  },
  {
    key: 'misc',
    value: 'misc',
    text: 'Other',
    icon: 'question'
  }
];

class RenderSelect extends Component {
  onChange = (e, { value }) => {
    const {
      input: { onChange }
    } = this.props;

    onChange(value);
  };

  render() {
    const {
      input: { name, onBlur, onFocus, value },
      meta: { active, error, touched },
      ...props
    } = this.props;

    return (
      <Form.Select
        error={Boolean(!active && touched && error)}
        id={name}
        onBlur={onBlur}
        onChange={this.onChange}
        onOpen={onFocus}
        value={value}
        {...props}
      />
    );
  }
}

class RenderCheckbox extends Component {
  onChange = (e, { checked }) => {
    const {
      input: { onBlur, onChange }
    } = this.props;

    onBlur(checked);
    onChange(checked);
  };

  render() {
    const {
      input: { name, value },
      meta: { error, touched },
      ...props
    } = this.props;

    return (
      <Form.Checkbox
        checked={Boolean(value)}
        error={Boolean(touched && error)}
        id={name}
        onChange={this.onChange}
        {...props}
      />
    );
  }
}

const required = value =>
  value || typeof value === 'string' ? undefined : 'Required';

const requiredTrue = value => (value === true ? undefined : 'Required');

const SurveyForm = reduxForm({ form: 'survey' })(
  class extends Component {
    render() {
      const { error, handleSubmit, submitting } = this.props;

      return (
        <Form
          error={Boolean(error)}
          loading={submitting}
          onSubmit={handleSubmit}
        >
          <Field
            component={RenderSelect}
            label="Intended use"
            name="use"
            options={useCases}
            required
            validate={required}
          />
          <Field
            component={RenderSelect}
            label="Level of education"
            name="edu"
            options={educationLevels}
            required
            validate={required}
          />
          <Field
            component={RenderSelect}
            label="Primary industry sector"
            name="sec"
            options={sectors}
            required
            validate={required}
          />
          <Field
            component={RenderCheckbox}
            label={
              <label>
                I have read and agree to the terms specified in the{' '}
                <Eula trigger={<a href>end-user license agreement</a>} />.
              </label>
            }
            name="eula"
            required
            validate={requiredTrue}
          />

          <Message error content={error} />
          <Form.Button content="Submit" />
        </Form>
      );
    }
  }
);

class Survey extends Component {
  onSubmit = async profile => {
    const { accessToken, setUserProfile } = this.props;

    try {
      const response = await window.fetch(
        `${process.env.REACT_APP_FEATHERS_DOMAIN}/users`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          method: 'PATCH',
          body: JSON.stringify({ profile })
        }
      );
      if (!response.ok) {
        throw new Error();
      }

      const [userProfile] = await response.json();
      const { profile: persisted } = userProfile;

      const { filled, missing } = ['eula', 'edu', 'sec', 'use'].reduce(
        ({ filled, missing }, field) => {
          const persistedField = persisted[field];
          filled = persistedField && filled;
          missing[field] = !persistedField;
          return { filled, missing };
        },
        {
          filled: true,
          missing: {}
        }
      );

      if (!filled) {
        throw new SubmissionError(
          Object.assign(missing, { _error: 'Please enter required fields.' })
        );
      }

      setUserProfile({ userProfile });
    } catch (err) {
      switch (err.name) {
        case 'SubmissionError':
          throw err;
        default:
          throw new SubmissionError({ _error: 'Network error' });
      }
    }
  };

  render() {
    return (
      <Segment basic>
        <Helmet>
          <title>QUI | Survey</title>
        </Helmet>

        <Container text>
          <Header as="h1" textAlign="center">
            Welcome to QUI
          </Header>
          <p>Please fill in this short form to get started.</p>

          <SurveyForm onSubmit={this.onSubmit} />
        </Container>
      </Segment>
    );
  }
}

const mapStateToProps = state => ({
  accessToken: selectors.auth.accessToken(state)
});

const mapDispatchToProps = {
  setUserProfile: actions.feathers.setUserProfile
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToJS(Survey));
