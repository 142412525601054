import { createActions } from 'redux-actions';

import { actions as authActions } from './auth';
import { actions as feathersActions } from './feathers';
import { actions as quiActions } from './qui';

export default createActions({
  ...authActions,
  ...feathersActions,
  ...quiActions
});
