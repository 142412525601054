import { fromJS, getIn } from 'immutable';
import { handleActions } from 'redux-actions';

import { createSelector } from 'reselect';

import { selectors as feathersSelectors } from '../feathers';

const defaultState = fromJS({
  isQUILoading: true,
  circuit: null,
  circuitBinary: new Buffer('EAMaAA==', 'base64'), // Empty circuit b64
  circuitSaved: false,
  circuitSaving: false,
  circuitLoading: false,
  circuitError: null,
  modal: null
});

export default handleActions(
  {
    QUI: {
      RESET: state => state.merge(defaultState),

      SET_QUI_LOADING: (state, { payload }) => state.merge(fromJS(payload)),

      SET_CIRCUIT_BINARY: (state, { payload }) => {
        const isDiff =
          !state.circuitBinary ||
          !payload.circuitBinary.equals(state.circuitBinary);
        return state.merge(
          fromJS({
            ...(isDiff ? { circuitSaved: false } : {}),
            ...payload
          })
        );
      },

      SET_CIRCUIT: (state, { payload }) =>
        state.merge(
          fromJS({
            circuitSaved: false,
            ...payload
          })
        ),

      SET_CIRCUIT_SAVED: (state, { payload }) => state.merge(fromJS(payload)),

      SET_CIRCUIT_SAVING: (state, { payload }) => state.merge(fromJS(payload)),

      SET_CIRCUIT_LOADING: (state, { payload }) => state.merge(fromJS(payload)),

      SET_CIRCUIT_ERROR: (state, { payload }) => state.merge(fromJS(payload)),

      OPEN_MODAL: (state, { payload }) => state.merge(fromJS(payload)),

      CLOSE_MODAL: state => state.merge({ modal: null })
    }
  },
  defaultState
);

export const selectors = (path => ({
  isQUILoading: state => getIn(state, path.concat(['isQUILoading'])),

  circuit: state => getIn(state, path.concat(['circuit'])),
  circuitBinary: state => getIn(state, path.concat(['circuitBinary'])),
  circuitSaved: state => getIn(state, path.concat(['circuitSaved'])),
  circuitSaving: state => getIn(state, path.concat(['circuitSaving'])),
  circuitLoading: state => getIn(state, path.concat(['circuitLoading'])),
  circuitError: state => getIn(state, path.concat(['circuitError'])),

  circuitWriteAllowed: createSelector(
    state => getIn(state, path.concat(['circuit', 'userId'])),
    state => feathersSelectors.userId(state),
    (ownerId, userId) => !ownerId || ownerId === userId
  ),

  currentModal: state => getIn(state, path.concat(['modal'])),
  isModalOpen: modalName => state =>
    getIn(state, path.concat(['modal'])) === modalName
}))(['qui']);

export { default as actions } from './actions';
export { default as saga } from './sagas';
