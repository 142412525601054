import { fromJS } from 'immutable';

import { applyMiddleware, createStore } from 'redux';

import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import createSagaMiddleware from 'redux-saga';

import reducer, { saga } from '.';

const initialState = () => {
  return fromJS({
    auth: {
      loaded: false
    }
  });
};

const sagaMiddleware = createSagaMiddleware();

export default createStore(
  reducer,
  initialState(),
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(saga);
