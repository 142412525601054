import React, { Component } from 'react';

import { Modal, Form, Button, Message, Icon } from 'semantic-ui-react';

import {
  reduxForm,
  Field,
  SubmissionError,
  submit,
  isSubmitting
} from 'redux-form/immutable';

import { withRouter } from 'react-router-dom';

import { actions, selectors } from '../../modules';
import { feathersClient } from '../../modules/feathers/utils';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ToJS from '../ToJS';

const required = value =>
  value || typeof value === 'string' ? undefined : 'Required';

class RenderInput extends Component {
  onChange = (e, { value }) => {
    const {
      input: { onChange }
    } = this.props;

    onChange(value);
  };

  render() {
    const {
      input: { name, onBlur, value },
      meta: { active, error, touched },
      ...props
    } = this.props;

    return (
      <Form.Input
        error={Boolean(!active && touched && error)}
        value={value}
        id={name}
        onBlur={onBlur}
        onChange={this.onChange}
        {...props}
      />
    );
  }
}

const SaveCircuitForm = reduxForm({ form: 'saveCircuit' })(
  class extends Component {
    render() {
      const { error, handleSubmit, loading } = this.props;

      return (
        <Form error={Boolean(error)} onSubmit={handleSubmit} loading={loading}>
          <Message error content={error} />

          <Field
            component={RenderInput}
            name="circuitName"
            placeholder="Circuit name"
            required
            validate={required}
          />
        </Form>
      );
    }
  }
);

class SaveCircuit extends Component {
  componentWillReceiveProps = nextProps => {
    if (!this.props.open && nextProps.open) {
      this.props.fetchCircuits();
      this.props.fetchUserProfile();
    }
  };

  save = () => {
    this.props.submitSaveCircuitForm();
  };

  onSaveSubmit = async form => {
    const { circuitBinary, history, closeModal } = this.props;

    const name = form.toJS().circuitName;

    try {
      const { _id } = await feathersClient.service('circuits').create({
        name,
        binary: circuitBinary
      });

      history.push(`/circuits/${_id}`);

      closeModal();
    } catch (e) {
      throw new SubmissionError({ _error: 'Failed to save circuit.' });
    }
  };

  render() {
    const {
      open,
      closeModal,
      submitting,
      saveLimitReached,
      saveLimit,
      circuitsLoading
    } = this.props;

    return (
      <Modal centered={false} open={open} size="tiny">
        <Modal.Header>Save Circuit</Modal.Header>
        <Modal.Content>
          {saveLimitReached ? (
            <Message
              error
              content={`Your save limit of ${saveLimit} circuits has been reached. Please delete one or more circuits if you wish to save a new circuit.`}
            />
          ) : (
            <SaveCircuitForm
              onSubmit={this.onSaveSubmit}
              loading={circuitsLoading}
              disabled={saveLimitReached}
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={closeModal}>Cancel</Button>
          <Button
            primary
            disabled={saveLimitReached || circuitsLoading}
            loading={submitting}
            onClick={this.save}
          >
            <Icon name="save" />
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  userProfile: selectors.feathers.userProfile(state),
  saveLimit: selectors.feathers.userSaveLimit(state),
  saveLimitReached: selectors.saveLimitReached(state),
  authenticated: selectors.authenticated(state),
  circuitBinary: selectors.qui.circuitBinary(state),
  circuitsLoading: selectors.feathers.circuitsLoading(state),
  submitting: isSubmitting('saveCircuit')(state),
  open: selectors.qui.isModalOpen('save')(state)
});

const mapDispatchToProps = dispatch => {
  return {
    submitSaveCircuitForm: () => dispatch(submit('saveCircuit')),
    fetchCircuits: bindActionCreators(actions.feathers.fetchCircuits, dispatch),
    fetchUserProfile: bindActionCreators(
      actions.feathers.fetchUserProfile,
      dispatch
    ),
    closeModal: bindActionCreators(actions.qui.closeModal, dispatch)
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ToJS(SaveCircuit))
);
