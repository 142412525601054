import { cps, delay, put } from 'redux-saga/effects';

import { actions } from '../..';
import { auth0 } from '../utils';

export default function* scheduleRenewal(initialDelay = 0) {
  yield delay(initialDelay);

  while (true) {
    try {
      const { accessToken, expiresIn: sExpiresIn } = yield cps(
        auth0.checkSession,
        {
          timeout: 2000
        }
      );
      const expiresIn = 1000 * sExpiresIn;

      yield put(actions.auth.setAccessToken({ accessToken, expiresIn }));

      yield delay(expiresIn);
    } catch (err) {
      window.localStorage.removeItem('auth0');

      if (err.error === 'login_required') {
        return;
      }
    }
  }
}
