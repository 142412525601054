import React, { Component, Fragment } from 'react';

import { Helmet } from 'react-helmet';
import { Loader } from 'semantic-ui-react';

export default class extends Component {
  constructor(props) {
    super(props);

    this.state = { active: false };
  }

  componentDidMount() {
    this.timerID = setInterval(() => {
      this.setState({ active: true });
    }, 200);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  render() {
    const { active } = this.state;
    const {
      loaderProps = { active, size: 'large' },
      title = 'QUI | Loading'
    } = this.props;

    return (
      <Fragment>
        <Helmet>
          <title>{title}</title>
        </Helmet>

        <Loader {...loaderProps} />
      </Fragment>
    );
  }
}
