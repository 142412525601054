import React, { Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Helmet } from 'react-helmet';

import Menu from './Menu';
import Loader from './Loader';
import Callback from './Callback';
import NotFound from './NotFound';
import Settings from './Settings';
import PasswordReset from './PasswordReset';
import Login from './Login';
import Home from './Home';
import ToJS from './ToJS';

import LoadCircuitModal from './Modals/LoadCircuit';
import SaveCircuitModal from './Modals/SaveCircuit';
import ShareCircuitModal from './Modals/ShareCircuit';

import { selectors } from '../modules';
import { connect } from 'react-redux';

import 'semantic-ui-css/semantic.min.css';

const AuthenticatedContent = () => (
  <Switch>
    <Redirect from="/callback" to="/" />
    <Route component={Home} exact path="/" />
    <Route component={Home} exact path="/circuits/:circuitId" />
    <Route component={PasswordReset} exact path="/reset_password" />
    <Route component={Settings} exact path="/settings" />
    <Route component={NotFound} />
  </Switch>
);

const UnauthenticatedContent = () => (
  <Switch>
    <Route component={Home} exact path="/" />
    <Route component={Home} exact path="/circuits/:circuitId" />
    <Route component={NotFound} exact path="/error" />
    <Route component={Login} />
  </Switch>
);

const App = ({ loaded, authenticated }) => (
  <Fragment>
    <Helmet>
      <meta
        name="description"
        content="QUI, a graphical tool from The University of Melbourne for visualising circuits for quantum computing algorithms."
      />
      <title>QUI</title>
    </Helmet>
    <Route
      children={routeProps => <Callback {...routeProps} />}
      exact
      path="/callback"
    />
    {loaded ? (
      <Fragment>
        <Menu />
        <main className="container-body">
          {authenticated ? (
            <AuthenticatedContent />
          ) : (
            <UnauthenticatedContent />
          )}
        </main>
      </Fragment>
    ) : (
      <main className="container-body">
        <Loader />
      </main>
    )}

    <LoadCircuitModal />
    <SaveCircuitModal />
    <ShareCircuitModal />
  </Fragment>
);

const mapStateToProps = state => ({
  authenticated: selectors.authenticated(state),
  loaded: selectors.loaded(state)
});

export default connect(mapStateToProps)(ToJS(App));
