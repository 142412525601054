import React from 'react';

import { Modal } from 'semantic-ui-react';
import EulaText from './EulaText';

export default ({ trigger }) => (
  <Modal closeIcon closeOnDimmerClick={false} trigger={trigger}>
    <Modal.Header>End-User License Agreement</Modal.Header>

    <Modal.Content scrolling>
      <Modal.Description>
        <EulaText />
      </Modal.Description>
    </Modal.Content>
  </Modal>
);
