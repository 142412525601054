import { all, call, select, put, takeLatest } from 'redux-saga/effects';

import { actions, selectors } from '..';
import { feathersClient, friendlyErrorMsg } from '../feathers/utils';

function* loadCircuit() {
  yield takeLatest('QUI/LOAD_CIRCUIT', function*({ payload: { id } }) {
    try {
      yield put(actions.qui.reset());
      yield put(actions.qui.setCircuitLoading({ circuitLoading: true }));
      const circuit = yield call(() =>
        feathersClient.service('circuits').get(id)
      );
      const circuitBinary = new Buffer(circuit.binary.data);
      delete circuit.binary;
      yield put(actions.qui.setCircuit({ circuit }));
      yield put(actions.qui.setCircuitBinary({ circuitBinary }));
      yield put(actions.qui.setCircuitSaved({ circuitSaved: true }));
      yield put(actions.qui.setCircuitLoading({ circuitLoading: false }));
    } catch (err) {
      yield put(
        actions.qui.setCircuitError({
          circuitError: {
            message: 'Failed to load circuit',
            code: err.code,
            details: friendlyErrorMsg(err, 'circuit')
          }
        })
      );
    }
  });
}

function* saveCircuit() {
  yield takeLatest('QUI/SAVE_CIRCUIT', function*() {
    try {
      const circuitOrig = yield select(selectors.qui.circuit);
      if (!circuitOrig) {
        yield put(actions.qui.openModal({ modal: 'save' })); // Not saved yet, bring up save modal
        return;
      }

      yield put(actions.qui.setCircuitError({ circuitError: null }));
      yield put(actions.qui.setCircuitSaving({ circuitSaving: true }));
      const circuit = {
        ...circuitOrig.toJS(), // toJS seems to be necessary?
        binary: yield select(selectors.qui.circuitBinary)
      };
      const updatedCircuit = yield call(() =>
        feathersClient.service('circuits').update(circuit._id, circuit)
      );
      delete circuit.binary;
      yield put(actions.qui.setCircuit({ circuit: updatedCircuit }));
      yield put(actions.qui.setCircuitSaved({ circuitSaved: true }));
      yield put(actions.qui.setCircuitSaving({ circuitSaving: false }));
    } catch (err) {
      yield put(actions.qui.setCircuitSaved({ circuitSaved: false }));
      yield put(actions.qui.setCircuitSaving({ circuitSaving: false }));
      yield put(
        actions.qui.setCircuitError({
          circuitError: {
            message: 'Failed to save circuit',
            code: err.code,
            details: friendlyErrorMsg(err, 'circuit')
          }
        })
      );
    }
  });
}

function* patchCircuit() {
  yield takeLatest('QUI/PATCH_CIRCUIT', function*({ payload }) {
    try {
      yield put(actions.qui.setCircuitError({ circuitError: null }));
      yield put(actions.qui.setCircuitSaving({ circuitSaving: true }));
      const { _id } = (yield select(selectors.qui.circuit)).toJS();
      const patchedCircuit = yield call(() =>
        feathersClient.service('circuits').patch(_id, payload)
      );
      yield put(actions.qui.setCircuit({ circuit: patchedCircuit }));
      yield put(actions.qui.setCircuitSaved({ circuitSaved: true }));
      yield put(actions.qui.setCircuitSaving({ circuitSaving: false }));
    } catch (err) {
      yield put(actions.qui.setCircuitSaved({ circuitSaved: false }));
      yield put(actions.qui.setCircuitSaving({ circuitSaving: false }));
      yield put(
        actions.qui.setCircuitError({
          circuitError: {
            message: 'Failed to update circuit',
            code: err.code,
            details: friendlyErrorMsg(err, 'circuit')
          }
        })
      );
    }
  });
}

export default function*() {
  yield all([call(loadCircuit), call(saveCircuit), call(patchCircuit)]);
}
