import React, { Component, Fragment } from 'react';

import { Loader } from 'semantic-ui-react';

import { ReactComponent as Logo } from './logo.svg';

import './index.css';

export default class extends Component {
  constructor(props) {
    super(props);

    this.state = { loaded: false };
  }

  componentDidMount() {
    const webglSupport = (() => {
      try {
        const mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(
          navigator.appVersion
        );
        const canvas = document.createElement('canvas');
        return (
          !mobile &&
          !!window.WebGLRenderingContext &&
          (canvas.getContext('webgl') ||
            canvas.getContext('experimental-webgl'))
        );
      } catch (e) {
        return false;
      }
    })();

    this.setState({
      loaded: true,
      webglSupport
    });
  }

  render() {
    const { loaded, webglSupport } = this.state;

    return (
      <div className="unity splash">
        <Logo className="logo" />

        {loaded &&
          ((webglSupport && (
            <Fragment>
              <Loader
                active
                inverted
                size="big"
                style={{
                  top: '75%'
                }}
              >
                Loading...
              </Loader>

              <p className="webgl">
                Best viewed with the latest version of Chrome or Firefox
              </p>
            </Fragment>
          )) || (
            <p className="webgl">
              Your browser lacks WebGL support. We recommend using the latest
              desktop version of Chrome or Firefox.
            </p>
          ))}

        <p className="copyright">
          Version {`${process.env.REACT_APP_VERSION}`}
          <br />
          &#0169;2020 The University of Melbourne
          <br />
          All rights reserved
        </p>
      </div>
    );
  }
}
