import React, { Component } from 'react';

import { Helmet } from 'react-helmet';
import { Container, Header, Image, Segment } from 'semantic-ui-react';
import Loader from '../Loader';
import { ReactComponent as Ket0000 } from './ket0000.svg';

import { actions, selectors } from '../../modules';
import { connect } from 'react-redux';
import ToJS from '../ToJS';

class Page extends Component {
  componentDidMount() {
    const { email, resetPassword } = this.props;

    resetPassword({ email });
  }

  render() {
    const { email } = this.props;

    return (
      <Segment basic textAlign="center">
        <Helmet>
          <title>QUI | Password reset</title>
        </Helmet>

        <Container text>
          <Image as={Ket0000} centered size="medium" />
          <Header as="h1">Password reset</Header>
          <p>
            We have sent an email to <a href={`mailto:${email}`}>{email}</a>{' '}
            with instructions to reset your password.
          </p>
        </Container>
      </Segment>
    );
  }
}

class PasswordReset extends Component {
  componentDidMount() {
    this.props.fetchUserProfile();
  }

  render() {
    const { email, resetPassword } = this.props;

    return email ? (
      <Page email={email} resetPassword={resetPassword} />
    ) : (
      <Loader />
    );
  }
}

const mapStateToProps = state => ({
  email: selectors.auth.email(state)
});

const mapDispatchToProps = {
  fetchUserProfile: actions.auth.fetchUserProfile,
  resetPassword: actions.auth.resetPassword
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToJS(PasswordReset));
