import React, { Component, Fragment } from 'react';

import Unity, { UnityContent } from 'react-unity-webgl';
import { Modal, Button, Header, Icon } from 'semantic-ui-react';
import Splash from './Splash';

import { actions, selectors } from '../../modules';

import { connect } from 'react-redux';
import ToJS from '../ToJS';

import './index.css';

class Simulator extends Component {
  constructor(props) {
    super(props);

    // Hack to suppress Unity alerts (but will still log them).
    // For some reason there are still occasional errors relating
    // to mounting/unmounting - need to investigate further.
    (function(f) {
      window.alert = function() {
        if (arguments[0] && arguments[0].includes('Unity')) {
          console.log('Suppressed Unity alert: ' + arguments[0]);
          return;
        }
        return f.apply(this, arguments);
      };
    })(window.alert);

    this.state = {
      isLoading: true,
      isQUIReady: false,
      currentCircuitBinary: this.props.circuitBinary,
      guestModalActive: !this.props.authenticated
    };

    this.unityContent = new UnityContent(
      '/QUI/Build.json',
      '/QUI/UnityLoader.js'
    );

    this.unityContent.on('GetAuthToken', () =>
      this.props.authenticated ? this.props.accessToken : ''
    );

    this.unityContent.on('GetCurrentCircuit', () =>
      this.state.currentCircuitBinary
        ? this.state.currentCircuitBinary.toString('base64')
        : ''
    );

    this.unityContent.on('NotifyQUIReady', () => {
      // Give an extra bit of time for initialisation.
      // Avoids any initial jitter being visible and ensures
      // that no circuit updates are received before
      // circuit state is pushed to the Unity app from React.
      // Hacky - might be a better way to handle this (TODO).
      setTimeout(() => this.setState({ isQUIReady: true }), 500);

      this.setFocus(false);
    });

    this.unityContent.on('NotifyCircuitUpdate', b64 => {
      if (!this.state.isQUIReady) return; // Block premature updates

      const buffer = new Buffer(b64, 'base64');
      this.setState({ currentCircuitBinary: buffer }, () => {
        this.props.setCircuitBinary({
          circuitBinary: buffer
        });
      });
    });

    this.unityContent.on('InvokeCommand', command => {
      const {
        openModal,
        saveCircuit,
        authenticated,
        circuitSaved,
        circuitWriteAllowed
      } = this.props;

      if (command === 'load') {
        openModal({ modal: 'load' });
      } else if (
        command === 'save' &&
        !circuitSaved &&
        authenticated &&
        circuitWriteAllowed
      ) {
        saveCircuit();
      } else if (command === 'save_as' && authenticated) {
        openModal({ modal: 'save' });
      }
    });

    this.unityContent.on('loaded', () => {
      this.setState({ isLoading: false });
      if (this.state.currentCircuitBinary) {
        this.loadCircuit(this.state.currentCircuitBinary);
      }
    });
  }

  componentDidUpdate(prevProps) {
    this.updateLoadingStatus();

    // Focus QUI only if a modal is not active
    // Important so that keyboard input is not hijacked for modal fields
    this.setFocus(this.props.currentModal === null);

    if (prevProps.circuitBinary === this.props.circuitBinary) return;

    // Only push update to Unity app if there is an actual circuit proto change
    const { currentCircuitBinary } = this.state;
    const updatedCircuitBinary = this.props.circuitBinary;

    if (
      !currentCircuitBinary ||
      !currentCircuitBinary.equals(updatedCircuitBinary || currentCircuitBinary)
    ) {
      this.loadCircuit(updatedCircuitBinary);
    }
    this.setState({ currentCircuitBinary: updatedCircuitBinary });
  }

  loadCircuit = buffer => {
    if (this.state.isLoading) return;
    this.unityContent.send(
      'Circuit',
      'LoadCircuitFromBase64Str',
      buffer.toString('base64')
    );
  };

  setFocus = inFocus => {
    if (this.state.isLoading) return;
    setTimeout(() => {
      inFocus
        ? this.unityContent.send('Circuit', '_WebGL_SetFocusOn')
        : this.unityContent.send('Circuit', '_WebGL_SetFocusOff');

      document.onkeydown = function(e) {
        // Suppress some native keyboard shortcuts when QUI in focus
        const ctrlPressed = e.ctrlKey;
        const suppressedKey = 'so'.indexOf(e.key) !== -1;
        return !(inFocus && ctrlPressed && suppressedKey);
      };
    }, 100); // Hack: Delay required for Unity to get key-down (otherwise key down events get stuck)
  };

  updateLoadingStatus = () => {
    const { isLoading, isQUIReady } = this.state;
    const { circuitLoading } = this.props;

    this.props.setQUILoading({
      isQUILoading: isLoading || !isQUIReady || circuitLoading
    });
  };

  closeGuestModal = () => {
    this.setState({
      guestModalActive: false
    });
  };

  render() {
    const { guestModalActive } = this.state;
    const { isQUILoading, logIn } = this.props;

    return (
      <Fragment>
        <Modal open={guestModalActive} basic size="small">
          <Header icon="user outline" content="Guest access" />
          <Modal.Content>
            <h3>
              You're not currently logged in to the QUI. You may continue, but
              you will be unable to save your circuits.
            </h3>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={logIn} inverted>
              <Icon name="user" />
              Log In / Sign Up
            </Button>
            <Button color="blue" onClick={this.closeGuestModal} inverted>
              <Icon name="checkmark" />
              Continue as Guest
            </Button>
          </Modal.Actions>
        </Modal>

        {isQUILoading ? (
          <div style={{ zIndex: 1 }}>
            <Splash />
          </div>
        ) : (
          ''
        )}

        <Unity className="unity" unityContent={this.unityContent} />
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  setQUILoading: actions.qui.setQuiLoading,
  setCircuitBinary: actions.qui.setCircuitBinary,
  saveCircuit: actions.qui.saveCircuit,
  openModal: actions.qui.openModal,
  closeModal: actions.qui.closeModal,
  logIn: actions.auth.logIn
};

const mapStateToProps = state => ({
  isQUILoading: selectors.qui.isQUILoading(state),
  circuitBinary: selectors.qui.circuitBinary(state),
  circuitLoading: selectors.qui.circuitLoading(state),
  circuitSaved: selectors.qui.circuitSaved(state),
  circuitWriteAllowed: selectors.qui.circuitWriteAllowed(state),
  authenticated: selectors.authenticated(state),
  accessToken: selectors.auth.accessToken(state),
  currentModal: selectors.qui.currentModal(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToJS(Simulator));
