import React, { Component, Fragment } from 'react';

import {
  Modal,
  Input,
  Button,
  Segment,
  Message,
  Icon
} from 'semantic-ui-react';

import { withRouter } from 'react-router-dom';

import { actions, selectors } from '../../modules';
import { connect } from 'react-redux';
import ToJS from '../ToJS';

class ShareCircuit extends Component {
  componentDidMount = async () => {
    this.props.fetchPublicGroupId();
  };

  componentWillReceiveProps = nextProps => {
    if (!this.props.open && nextProps.open) {
      this.props.fetchPublicGroupId();
    }
  };

  enableSharing = () => {
    this.updateCircuitGroup(this.props.publicGroupId);
  };

  disableSharing = () => {
    this.updateCircuitGroup(null);
  };

  updateCircuitGroup = groupId => {
    this.props.patchCircuit({ groupId });
  };

  circuitSharedPublic = () => {
    if (!this.props.circuit) return false;

    const { groupId } = this.props.circuit;
    const { publicGroupId } = this.props;

    return groupId === publicGroupId;
  };

  render() {
    const {
      open,
      closeModal,
      circuitSaving,
      circuit,
      publicGroupId
    } = this.props;
    const loading = !publicGroupId;
    const shared = this.circuitSharedPublic();

    return (
      <Modal centered={false} open={open} size="small">
        <Modal.Header>Share Circuit</Modal.Header>
        <Modal.Content>
          {loading ? (
            <Segment basic loading />
          ) : shared ? (
            <Fragment>
              <Message
                info
                content="The URL below may be used to share your circuit with others. You can disable sharing at any time, however, please note that users could make their own copies of your circuit in the meantime."
              />
              <Input
                fluid
                label={'Share link:'}
                value={`${window.location.protocol}//${window.location.host}/circuits/${circuit._id}`}
              />
            </Fragment>
          ) : (
            <Fragment>
              {!circuit ? (
                <Message
                  error
                  content="You need to save your circuit before sharing will be enabled."
                />
              ) : (
                ''
              )}
              <span>
                Sharing enables you to allow other users to access your circuit
                in a read-only capacity. Please confirm that you would like to
                share your circuit and you will be provided with a URL to
                distribute.
              </span>
            </Fragment>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={closeModal}>Close</Button>
          {shared ? (
            <Button
              negative
              loading={circuitSaving}
              disabled={loading}
              onClick={this.disableSharing}
            >
              <Icon name="lock" />
              Disable Sharing
            </Button>
          ) : (
            <Button
              primary
              loading={circuitSaving}
              disabled={loading || !circuit}
              onClick={this.enableSharing}
            >
              <Icon name="share alternate" />
              Enable Sharing
            </Button>
          )}
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  circuit: selectors.qui.circuit(state),
  circuitSaving: selectors.qui.circuitSaving(state),
  publicGroupId: selectors.feathers.publicGroupId(state),
  open: selectors.qui.isModalOpen('share')(state)
});

const mapDispatchToProps = {
  fetchPublicGroupId: actions.feathers.fetchPublicGroupId,
  patchCircuit: actions.qui.patchCircuit,
  closeModal: actions.qui.closeModal
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ToJS(ShareCircuit))
);
