import { put } from 'redux-saga/effects';

import { actions } from '../..';

export default function*() {
  try {
    const {
      accessToken,
      expiresIn: expiresInFromTimestamp,
      timestamp
    } = JSON.parse(window.localStorage.getItem('auth0'));

    const now = Date.now();
    const expiresIn = timestamp + expiresInFromTimestamp - now;

    if (expiresIn <= 0) {
      throw new Error();
    }

    yield put(
      actions.auth.setAccessToken({ accessToken, expiresIn, timestamp: now })
    );

    return expiresIn;
  } catch (err) {
    window.localStorage.removeItem('auth0');
    return 0;
  }
}
