import { Component } from 'react';

import { actions } from '../modules';
import { connect } from 'react-redux';
import ToJS from './ToJS';

class Callback extends Component {
  componentDidMount() {
    const {
      location: { hash },
      match,
      parseHash,
      skipCallback
    } = this.props;

    if (match) {
      parseHash({ hash });
    } else {
      skipCallback();
    }
  }

  render() {
    return null;
  }
}

const mapDispatchToProps = {
  parseHash: actions.auth.parseHash,
  skipCallback: actions.auth.skipCallback
};

export default connect(
  null,
  mapDispatchToProps
)(ToJS(Callback));
