import React, { Component } from 'react';

import Loader from './Loader';

import { actions } from '../modules';
import { connect } from 'react-redux';
import ToJS from './ToJS';

class Login extends Component {
  componentDidMount() {
    this.props.logIn();
  }

  render() {
    return <Loader title="QUI | Logging in" />;
  }
}

const mapDispatchToProps = {
  logIn: actions.auth.logIn
};

export default connect(
  null,
  mapDispatchToProps
)(ToJS(Login));
