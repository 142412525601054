import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import { Container, Header, Input, Segment } from 'semantic-ui-react';
import Loader from './Loader';

import { actions, selectors } from '../modules';
import { connect } from 'react-redux';
import ToJS from './ToJS';

class Settings extends Component {
  componentDidMount() {
    this.props.fetchUserProfile();
  }

  render() {
    const { email } = this.props;

    return email ? (
      <Segment basic>
        <Helmet>
          <title>QUI | Settings</title>
        </Helmet>

        <Container text>
          <Header as="h1" size="huge" textAlign="center">
            Profile Settings
          </Header>

          <label>
            <Header as="h2">Email address</Header>
            <Input defaultValue={email} disabled fluid transparent />
          </label>

          <Header as="h2">Password</Header>
          <p>
            <Link to="/reset_password">Reset your password.</Link>
          </p>
        </Container>
      </Segment>
    ) : (
      <Loader />
    );
  }
}

const mapStateToProps = state => ({
  email: selectors.auth.email(state)
});

const mapDispatchToProps = {
  fetchUserProfile: actions.auth.fetchUserProfile
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToJS(Settings));
