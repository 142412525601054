import React, { Component } from 'react';

import Simulator from './Simulator';
import Survey from './Survey';
import NotFound from './NotFound';
import Loader from './Loader';

import { actions, selectors } from '../modules';
import { connect } from 'react-redux';
import ToJS from './ToJS';

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialLoading: true
    };
  }

  componentDidUpdate() {
    if (this.props.circuit && this.state.initialLoading) {
      this.setState({ initialLoading: false });
    }
  }

  componentDidMount() {
    this.unlistenHistory = this.props.history.listen(({ pathname }) => {
      const match = pathname.match('^/circuits/([^/]+)/?');
      const circuitId = match ? match[1] : null;
      this.loadCircuit(circuitId);
    });

    this.props.fetchUserProfile();
    this.loadCircuit(this.props.match.params.circuitId);
  }

  componentWillUnmount() {
    this.unlistenHistory();
  }

  loadCircuit = circuitId => {
    if (circuitId) {
      this.props.loadCircuit({ id: circuitId });
    } else {
      this.props.resetQUI(); // New circuit
      this.setState({ initialLoading: false });
    }
  };

  render() {
    const { surveyed, userProfile, circuitError } = this.props;
    const { initialLoading } = this.state;

    if (
      circuitError &&
      (circuitError.code === 404 || circuitError.code === 403)
    ) {
      return <NotFound />;
    } else if (userProfile && !userProfile.isGuest && !surveyed) {
      return <Survey />;
    } else if (!initialLoading) {
      return <Simulator />;
    } else {
      return (
        <main className="container-body">
          <Loader />
        </main>
      );
    }
  }
}

const mapStateToProps = state => ({
  circuit: selectors.qui.circuit(state),
  circuitError: selectors.qui.circuitError(state),
  userProfile: selectors.feathers.userProfile(state),
  surveyed: selectors.surveyed(state)
});

const mapDispatchToProps = {
  fetchUserProfile: actions.feathers.fetchUserProfile,
  resetQUI: actions.qui.reset,
  loadCircuit: actions.qui.loadCircuit
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToJS(Home));
