import React from 'react';
import { isImmutable } from 'immutable';

export default WrappedComponent => wrappedComponentProps => {
  const propsJS = Object.entries(wrappedComponentProps).reduce(
    (newProps, [key, value]) => {
      newProps[key] = isImmutable(value) ? value.toJS() : value;
      return newProps;
    },
    {}
  );

  return <WrappedComponent {...propsJS} />;
};
