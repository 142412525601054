export default {
  QUI: {
    RESET: undefined,

    SET_QUI_LOADING: ({ isQUILoading } = {}) => ({ isQUILoading }),

    LOAD_CIRCUIT: ({ id } = {}) => ({ id }),

    SAVE_CIRCUIT: undefined,

    PATCH_CIRCUIT: (payload = {}) => payload,

    SET_CIRCUIT_BINARY: ({ circuitBinary } = {}) => ({ circuitBinary }),

    SET_CIRCUIT: ({ circuit } = {}) => ({ circuit }),

    SET_CIRCUIT_SAVED: ({ circuitSaved } = {}) => ({ circuitSaved }),

    SET_CIRCUIT_SAVING: ({ circuitSaving } = {}) => ({ circuitSaving }),

    SET_CIRCUIT_LOADING: ({ circuitLoading } = {}) => ({ circuitLoading }),

    SET_CIRCUIT_ERROR: ({ circuitError } = {}) => ({ circuitError }),

    OPEN_MODAL: ({ modal } = {}) => ({ modal }),

    CLOSE_MODAL: undefined
  }
};
