import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import {
  Modal,
  Input,
  Tab,
  List,
  Button,
  Icon,
  Message
} from 'semantic-ui-react';

import Moment from 'react-moment';

import { actions, selectors } from '../../modules';
import { connect } from 'react-redux';
import ToJS from '../ToJS';

import { feathersClient } from '../../modules/feathers/utils';

class LoadCircuit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchFilterText: '',
      deletingCircuit: false,
      error: null
    };
  }

  componentWillReceiveProps = nextProps => {
    if (!this.props.open && nextProps.open) {
      this.props.fetchCircuits();
      this.props.fetchUserProfile();
      this.props.fetchPublicGroupId();
    }
  };

  onSearchChange = ({ target: { value } }) => {
    this.setState({
      searchFilterText: value
    });
  };

  deleteCircuit = async circuitId => {
    const { circuit, history } = this.props;
    const deletedOpenCircuit = circuit && circuitId === circuit._id;

    try {
      this.setState({
        deletingCircuit: true,
        error: null
      });
      await feathersClient.service('circuits').remove(circuitId);
      this.setState({
        deletingCircuit: false
      });
      this.props.fetchCircuits();
      if (deletedOpenCircuit) {
        history.push('/'); // Create new circuit
      }
    } catch (e) {
      this.setState({
        deletingCircuit: false,
        error: 'Failed to delete circuit.'
      });
    }
  };

  generateCircuitListPane = (filter, allowDelete) => {
    const {
      circuit,
      circuits,
      circuitsLoading,
      userProfile,
      publicGroupId,
      closeModal
    } = this.props;

    const { deletingCircuit, error, searchFilterText } = this.state;

    const searchFilter = ({ name }) =>
      name.toLowerCase().includes(searchFilterText.toLowerCase());

    const filteredCircuits = circuits.filter(filter);
    const filteredCircuitsSearched = filteredCircuits.filter(searchFilter);

    return (
      <Tab.Pane loading={circuitsLoading || deletingCircuit || !userProfile}>
        {error ? <Message error content={error} /> : ''}
        <Input
          fluid
          icon="search"
          placeholder="Search..."
          value={searchFilterText}
          onChange={this.onSearchChange}
        />
        <List selection verticalAlign="middle">
          {filteredCircuitsSearched.length > 0 ? (
            filteredCircuitsSearched.map(
              ({ _id, groupId, name, updatedAt }) => {
                const circuitOpen = circuit && _id === circuit._id;
                const circuitIsShared = groupId === publicGroupId;
                return (
                  <List.Item
                    as={Link}
                    to={`/circuits/${_id}`}
                    onClick={closeModal}
                    key={_id}
                  >
                    {allowDelete ? (
                      <List.Content floated="right">
                        <Modal
                          size="tiny"
                          onClick={e => e.stopPropagation()}
                          trigger={
                            <Button
                              onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            >
                              <Icon name="delete" />
                              Delete
                            </Button>
                          }
                          header="Are you sure?"
                          content={`Please confirm that you wish to delete the circuit '${name}'.`}
                          actions={[
                            'Cancel',
                            {
                              key: 'delete',
                              content: 'Delete',
                              negative: true,
                              icon: 'delete',
                              onClick: () => this.deleteCircuit(_id)
                            }
                          ]}
                        />
                      </List.Content>
                    ) : (
                      ''
                    )}
                    <List.Header>
                      {name}{' '}
                      {circuitIsShared ? <Icon name="share alternate" /> : ''}
                    </List.Header>
                    Last updated <Moment fromNow>{updatedAt}</Moment>
                    {circuitOpen ? ' (currently opened in editor)' : ''}
                  </List.Item>
                );
              }
            )
          ) : filteredCircuits.length > 0 ? (
            <Message content="No circuits match the search." />
          ) : (
            <Message content="No circuits have been saved here yet." />
          )}
        </List>
      </Tab.Pane>
    );
  };

  generatePanes = () => {
    const { authenticated, userProfile, saveLimit, circuits } = this.props;
    const isMe = id => {
      return userProfile && authenticated ? id === userProfile._id : false;
    };
    const circuitIsMine = ({ userId }) => isMe(userId);
    const circuitIsNotMine = circuit => !circuitIsMine(circuit);
    const authenticatedPanes = authenticated
      ? [
          {
            menuItem: {
              key: 'myCircuits',
              icon: 'save',
              content: `My Circuits (${
                circuits.filter(circuitIsMine).length
              }/${saveLimit})`
            },
            render: () => this.generateCircuitListPane(circuitIsMine, true)
          }
        ]
      : [];
    return [
      ...authenticatedPanes,
      {
        menuItem: {
          key: 'examples',
          icon: 'cloud download',
          content: 'Examples'
        },
        render: () => this.generateCircuitListPane(circuitIsNotMine, false)
      }
    ];
  };

  render() {
    const { open, closeModal } = this.props;
    return (
      <Modal centered={false} open={open}>
        <Modal.Header>Load Circuit</Modal.Header>
        <Modal.Content>
          <Tab panes={this.generatePanes()} />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={closeModal}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  userProfile: selectors.feathers.userProfile(state),
  publicGroupId: selectors.feathers.publicGroupId(state),
  saveLimit: selectors.feathers.userSaveLimit(state),
  authenticated: selectors.authenticated(state),
  circuit: selectors.qui.circuit(state),
  circuits: selectors.feathers.circuits(state),
  circuitsLoading: selectors.feathers.circuitsLoading(state),
  open: selectors.qui.isModalOpen('load')(state)
});

const mapDispatchToProps = {
  fetchCircuits: actions.feathers.fetchCircuits,
  fetchPublicGroupId: actions.feathers.fetchPublicGroupId,
  fetchUserProfile: actions.feathers.fetchUserProfile,
  closeModal: actions.qui.closeModal
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ToJS(LoadCircuit))
);
