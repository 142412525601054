export default {
  AUTH: {
    FETCH_USER_PROFILE: undefined,

    LOADED: undefined,

    LOG_IN: undefined,

    LOG_OUT: ({
      returnTo = process.env.REACT_APP_DOMAIN,
      clientID = process.env.REACT_APP_AUTH0_CLIENT_ID
    } = {}) => ({
      returnTo,
      clientID
    }),

    PARSE_HASH: ({ hash = window.location.hash } = {}) => ({ hash }),

    RESET: undefined,

    RESET_PASSWORD: ({
      email,
      connection = 'Username-Password-Authentication'
    } = {}) => ({
      email,
      connection
    }),

    SET_ACCESS_TOKEN: ({
      accessToken,
      expiresIn,
      timestamp = Date.now()
    } = {}) => ({
      jwt: {
        accessToken,
        expiresIn,
        timestamp
      }
    }),

    SET_USER_PROFILE: ({ userProfile } = {}) => ({ userProfile }),

    SKIP_CALLBACK: undefined
  }
};
