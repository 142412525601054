import { fromJS, getIn } from 'immutable';
import { handleActions } from 'redux-actions';

const defaultState = fromJS({
  jwt: {
    accessToken: undefined,
    expiresIn: undefined,
    timestamp: undefined
  },
  userProfile: undefined
});

export default handleActions(
  {
    AUTH: {
      RESET: state => state.merge(defaultState),

      LOADED: state => state.set('loaded', true),

      SET_ACCESS_TOKEN: (state, { payload }) =>
        state.merge(fromJS(payload)).set('loaded', true),

      SET_USER_PROFILE: (state, { payload }) => state.merge(fromJS(payload))
    }
  },
  defaultState
);

export const selectors = (path => ({
  accessToken: state => getIn(state, path.concat(['jwt', 'accessToken'])),

  expiresIn: state => getIn(state, path.concat(['jwt', 'expiresIn'])),

  timestamp: state => getIn(state, path.concat(['jwt', 'timestamp'])),

  email: state => getIn(state, path.concat(['userProfile', 'email'])),

  loaded: state => getIn(state, path.concat(['loaded']))
}))(['auth']);

export { default as actions } from './actions';
export { default as saga } from './sagas';
