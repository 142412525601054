import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { actions } from '..';
import { feathersClient } from './utils';

function* fetchUser() {
  yield takeLatest('FEATHERS/FETCH_USER_PROFILE', function*() {
    try {
      const [userProfile] = yield call(() =>
        feathersClient.service('users').find()
      );
      yield put(actions.feathers.setUserProfile({ userProfile }));
    } catch (err) {
      yield put(actions.feathers.reset());
    }
  });
}

function* patchUser() {
  yield takeEvery('FEATHERS/PATCH_USER_PROFILE', function*({
    payload: { userProfile }
  }) {
    try {
      const [updatedUserProfile] = yield call(() =>
        feathersClient.service('users').patch(JSON.stringify(userProfile))
      );
      yield put(
        actions.feathers.setUserProfile({ userProfile: updatedUserProfile })
      );
    } catch (err) {
      yield put(actions.feathers.reset());
    }
  });
}

function* fetchCircuits() {
  yield takeLatest('FEATHERS/FETCH_CIRCUITS', function*() {
    try {
      yield put(actions.feathers.setCircuitsLoading({ circuitsLoading: true }));
      const circuits = yield call(() =>
        feathersClient.service('circuits').find()
      );

      yield put(actions.feathers.setCircuits({ circuits }));
      yield put(
        actions.feathers.setCircuitsLoading({ circuitsLoading: false })
      );
    } catch (err) {
      yield put(actions.feathers.reset());
    }
  });
}

function* fetchPublicGroupId() {
  yield takeLatest('FEATHERS/FETCH_PUBLIC_GROUP_ID', function*() {
    try {
      const [{ _id }] = yield call(() =>
        feathersClient.service('groups').find({
          name: 'public'
        })
      );
      yield put(actions.feathers.setPublicGroupId({ publicGroupId: _id }));
    } catch (err) {}
  });
}

export default function*() {
  yield all([
    call(fetchUser),
    call(patchUser),
    call(fetchCircuits),
    call(fetchPublicGroupId)
  ]);
}
