import { buffers } from 'redux-saga';
import {
  actionChannel,
  all,
  call,
  select,
  cps,
  put,
  race,
  take,
  takeLatest
} from 'redux-saga/effects';

import { actions, selectors } from '../..';
import { auth0 } from '../utils';

import callbackSaga from './callback';
import setFromLocalStorageSaga from './setFromLocalStorage';
import scheduleRenewalSaga from './scheduleRenewal';

import { feathersClient } from '../../feathers/utils';

function* authFlow() {
  const { callback } = yield race({
    callback: take('AUTH/PARSE_HASH'),
    skipCallback: take('AUTH/SKIP_CALLBACK')
  });

  const initialDelay = callback
    ? yield call(callbackSaga, callback.payload)
    : yield call(setFromLocalStorageSaga);

  const sessionExists = yield select(selectors.auth.accessToken);

  if (!sessionExists) {
    yield put(actions.auth.reset());
    yield put(actions.auth.loaded());

    yield takeLatest('AUTH/LOG_IN', function*() {
      yield call(logIn);
    });

    return;
  }

  const { logOutAction } = yield race({
    logOutAction: take('AUTH/LOG_OUT'),
    renewalLoop: call(scheduleRenewalSaga, initialDelay)
  });

  if (logOutAction) {
    yield call(logOut, logOutAction.payload);
    yield call(feathersClient.logout);
  } else {
    yield call(logIn);
  }
}

function* fetchUserProfile() {
  const fetchUserProfileChannel = yield actionChannel(
    'AUTH/FETCH_USER_PROFILE'
  );

  const setAccessTokenChannel = yield actionChannel(
    'AUTH/SET_ACCESS_TOKEN',
    buffers.sliding(1)
  );

  yield takeLatest(fetchUserProfileChannel, function*() {
    try {
      const {
        payload: {
          jwt: { accessToken }
        }
      } = yield take(setAccessTokenChannel);

      const userProfile = yield cps(auth0.userInfo, accessToken);

      yield put(actions.auth.setUserProfile({ userProfile }));
    } catch (err) {
      //
    }
  });
}

function* authFeathersClient() {
  yield takeLatest('AUTH/SET_ACCESS_TOKEN', function*({
    payload: {
      jwt: { accessToken }
    }
  }) {
    try {
      yield call(feathersClient.logout);
      yield call(feathersClient.authenticate, {
        strategy: 'jwt',
        accessToken
      });
    } catch (err) {
      //
    }
  });
}

function* resetPassword() {
  yield takeLatest('AUTH/RESET_PASSWORD', function*({ payload }) {
    try {
      yield cps(auth0.changePassword, payload);
    } catch (err) {
      //
    }
  });
}

function* logIn() {
  yield call(auth0.authorize);
}

function* logOut(options) {
  window.localStorage.removeItem('auth0');
  yield call(auth0.logOut, options);
}

function* persistToLocalstorage() {
  yield takeLatest('AUTH/SET_ACCESS_TOKEN', ({ payload: { jwt } }) => {
    try {
      window.localStorage.setItem('auth0', JSON.stringify(jwt));
    } catch (err) {
      //
    }
  });
}

export default function*() {
  yield all([
    call(authFlow),
    call(authFeathersClient),
    call(fetchUserProfile),
    call(resetPassword),
    call(persistToLocalstorage)
  ]);
}
