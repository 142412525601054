import { WebAuth } from 'auth0-js';

class Auth0 {
  webAuth = new WebAuth({
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
    responseType: 'token id_token',
    scope: 'openid profile email'
  });

  authorize = options => this.webAuth.authorize(options);
  changePassword = (options, cb) => this.webAuth.changePassword(options, cb);
  checkSession = (options, cb) => this.webAuth.checkSession(options, cb);
  logOut = options => this.webAuth.logout(options);
  parseHash = (options, cb) => this.webAuth.parseHash(options, cb);
  userInfo = (accessToken, cb) => this.webAuth.client.userInfo(accessToken, cb);
}

export const auth0 = new Auth0();
