import React, { Fragment } from 'react';

import './index.css';

export default () => (
  <Fragment>
    <p>
      BY USING THE QUI SOFTWARE and associated source code, software, function
      libraries, applications, user manuals and documentation (
      <strong>SOFTWARE</strong>
      ), YOU (AS AN INDIVIDUAL USING THE SOFTWARE AND ANY SINGLE LEGAL ENTITY
      FOR WHICH YOU REPRESENT) (<strong>YOU</strong>) ARE ENTERING INTO A
      LEGALLY BINDING AGREEMENT WITH THE UNIVERSITY OF MELBOURNE (
      <strong>UNIVERSITY</strong>
      ). IN CONSIDERATION OF YOU USING THE SOFTWARE, YOU AGREE TO BE BOUND BY,
      AND TO ABIDE BY, THESE TERMS AND CONDITIONS.
    </p>

    <ol>
      <li>
        <p>
          <strong>License.</strong> Subject to You complying with the conditions
          in clause 2, the University grants to You a limited, non-exclusive,
          non-transferable, revocable licence (excluding any right of
          sub-licence) to use the Software:
          <ol className="eula-sublist">
            <li>
              in a manner consistent with its design and documentation; and
            </li>
            <li>for Your personal non-commercial use only.</li>
          </ol>
        </p>
      </li>
      <li>
        <p>
          <strong>Restrictions on use.</strong> You agree to only use the
          Software in accordance with these terms and applicable laws. Without
          limiting the previous sentence, You must not, without the University's
          prior written agreement:
          <ol className="eula-sublist">
            <li>
              print, copy or reproduce the Software by any means or in any form
              (except to the extent permitted by Division 4A of Part III of the{' '}
              <em>Copyright Act 1968</em> (Cth));
            </li>
            <li>
              give, lease, assign, license, sub-license, transfer, distribute,
              disclose, release or disseminate the Software in any form to any
              other person (or purport to do so);
            </li>
            <li>
              modify, adapt, alter, reverse engineer or decompile, or create
              derivative works or functionally equivalent software from, the
              Software (except to the extent permitted by Division 4A of Part
              III of the <em>Copyright Act 1968</em> (Cth));
            </li>
            <li>
              alter, change, remove or obscure any notices or other indications
              (including copyright notices) as to the ownership of the Software;
              or
            </li>
            <li>
              publicly release the Software, or incorporate the Software into
              any product to be made publicly or commercially available.
            </li>
          </ol>
        </p>
      </li>
      <li>
        <p>
          <strong>Ownership.</strong> You acknowledge and agree that the
          University is the sole owner of the Software and, all intellectual
          property rights subsisting in the Software, and that the Licence does
          not transfer or assign to You (or any other person) any copyright or
          other intellectual property rights subsisting in the Software.  You
          further acknowledge and agree that the University will own any and all
          modifications, adaptations and alterations to the Software, or
          derivative works or functionally equivalent software created from the
          Software and that You assign to the University all copyright
          (including future copyright) and other intellectual property rights
          subsisting in any such modifications, adaptations, alterations,
          derivative works or functionally equivalent software to the University
          created by You, irrespective of whether the University's prior written
          agreement was obtained.
        </p>
      </li>
      <li>
        <p>
          <strong>Disclaimers.</strong> The Software is licensed to You on an
          "as is" basis and without any representation as to functionality,
          performance, suitability or fitness for purpose. You acknowledge and
          agree that, to the extent permitted under law, and subject to clause
          5, the University makes no representations, warranties or guarantees:
          <ol className="eula-sublist">
            <li>
              in relation to the functionality, performance, availability,
              suitability, continuity, reliability, accuracy, currency or
              security of the Software; or
            </li>
            <li>
              that the Software is free from computer viruses or any other
              defect or error which may affect Your software or systems or third
              party software or systems.
            </li>
          </ol>
          You further acknowledge and agree that the University has no
          obligation to (and makes no representation that it will) maintain or
          update, or correct any errors or defects in, the Software.
        </p>
      </li>
      <li>
        <p>
          <strong>Consumer guarantees.</strong> To the extent permitted by law,
          any conditions, warranties, guarantees, rights, remedies, liabilities
          and other terms implied or conferred by statute, custom or the general
          law that impose any liability or obligation on the University are
          excluded under these terms. However, if a supply under these terms and
          conditions is a supply of goods or services to a consumer within the
          meaning of the Australian Consumer Law (contained in Schedule 2 to the
          <em>Competition and Consumer Act 2010</em> (Cth), as amended or
          replaced from time to time), nothing contained in these terms
          excludes, restricts or modifies the application of any provision, the
          exercise of any right or remedy, or the imposition of any liability
          under the Australian Consumer Law, provided that, to the extent that
          the Australian Consumer Law permits the University to limit its
          liability, then the University's liability is limited to (at its
          option):
          <ol className="eula-sublist">
            <li>
              in the case of services, the cost of supplying the services again
              or payment of the cost of having the services supplied again; and
            </li>
            <li>
              in the case of software or other goods, the cost of replacing the
              goods, supplying equivalent goods or having the goods repaired, or
              payment of the cost of replacing the goods, supplying equivalent
              goods or having the goods repaired.
            </li>
          </ol>
        </p>
      </li>
      <li>
        <p>
          <strong>Exclusion of liability.</strong> Subject to clause 5 and to
          the extent permitted by law, the University excludes liability for all
          direct, indirect or consequential liabilities, losses, damages, costs
          and expenses (including, without limitation, loss of revenue, loss of
          goodwill, downtime costs, loss of profit, loss of or damage to
          reputation, loss under or in relation to any other contract, loss of
          data, loss of use of data or loss of anticipated savings or benefits),
          whether arising in contract, tort (including negligence) or otherwise,
          suffered or incurred by any person in connection with the Software
          (including Your downloading and/or use of the Software) and the
          termination of the Agreement by the University.
        </p>
      </li>
      <li>
        <p>
          <strong>Indemnity.</strong> You indemnify the University (and its
          subsidiaries, employees and agents) against all loss, actions,
          proceedings, costs and expenses (including legal fees), claims and
          damages of any kind whatsoever caused directly or indirectly by, or
          arising directly or indirectly out of or in connection with, Your
          downloading and/or use of the Software or any breach by You of these
          terms.
        </p>
      </li>
      <li>
        <p>
          <strong>Termination.</strong> Without prejudice to any other rights or
          remedies that the University may have against You under the agreement
          formed by these terms or at law, the University may at its absolute
          discretion, by giving You written notice, immediately terminate this
          agreement. The termination of this agreement will result in the
          immediate and automatic revocation of the licence. If the University
          terminates this agreement (and revokes the Licence), You must
          immediately cease using the Software and (at the University's option)
          delete or otherwise destroy all copies of the Software in Your
          possession.
        </p>
      </li>
      <li>
        <p>
          <strong>General.</strong> These terms constitute the entire agreement
          between You and the University in relation to their subject matter and
          supersede and cancel all prior understandings, negotiations and
          agreements in connection with that subject matter, and may only be
          varied by a written agreement between You and the University. If any
          of provision of these terms is invalid or unenforceable, it may be
          severed from these terms and the remaining provisions of these terms
          continue in force. These terms are governed by the laws in force in
          Victoria, Australia, and You unconditionally submit to the exclusive
          jurisdiction of the courts of Victoria, Australia.
        </p>
      </li>
      <li>
        <p>
          <strong>Privacy.</strong> The collection of personal information by
          the University of Melbourne (University) is governed by the{' '}
          <em>Privacy and Data Protection Act 2014</em> (Vic) and{' '}
          <em>Health Records Act 2000</em> (Vic). The University is also
          considered to be a data controller for the purposes of the EU General
          Data Protection Regulation 2016/679 (GDPR) in relation to the
          collection of personal information from individuals located in the EU.
          Please refer to our{' '}
          <a href="http://policy.unimelb.edu.au/MPF1104">Privacy Policy</a> and
          our{' '}
          <a href="https://www.unimelb.edu.au/governance/compliance/privacy">
            Privacy webpage
          </a>{' '}
          for information about how the University handles personal information.
        </p>
        <p>
          You acknowledge and accept that the University will collect and store
          Your personal information provided by You when You use the Software.
          The primary purpose of collecting Your personal information is to
          verify your identity, and to facilitate your usage of the Software.
        </p>
        <p>
          The University will take all reasonable steps to ensure that the
          personal information it holds is accurate and complete and that it is
          protected from misuse, loss, unauthorised access or disclosure.
        </p>
        <p>
          The University will disclose Your personal information to a third
          party service provider in NSW for storage purposes. By providing your
          personal information to the University You:
          <ol className="eula-sublist">
            <li>
              acknowledge that the University will transfer Your personal
              information to the University’s third party service provider in
              NSW for storage purposes; and
            </li>
            <li>
              having been so informed, expressly consent to the transfer of your
              personal information to the University’s third party service
              provider for storage purposes.
            </li>
          </ol>
          Personal information is being collected by the Hollenberg group at the
          University. You may request access to, or correction of, information
          we hold about you at any time by contacting us at{' '}
          <a href="mailto:qui@lists.unimelb.edu.au">qui@lists.unimelb.edu.au</a>
          . For general information of the University’s privacy compliance,
          please send an email to{' '}
          <a href="mailto:privacy-officer@unimelb.edu.au">
            privacy-officer@unimelb.edu.au
          </a>{' '}
          if you would like further information.
        </p>
      </li>
    </ol>
  </Fragment>
);
