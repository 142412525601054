export default {
  FEATHERS: {
    FETCH_USER_PROFILE: undefined,

    PATCH_USER_PROFILE: ({ userProfile } = {}) => ({ userProfile }),

    RESET: undefined,

    SET_USER_PROFILE: ({ userProfile } = {}) => ({ userProfile }),

    FETCH_CIRCUITS: undefined,

    SET_CIRCUITS: ({ circuits } = {}) => ({ circuits }),

    SET_CIRCUITS_LOADING: undefined,

    FETCH_PUBLIC_GROUP_ID: undefined,

    SET_PUBLIC_GROUP_ID: ({ publicGroupId } = {}) => ({ publicGroupId })
  }
};
