import { combineReducers } from 'redux-immutable';
import { createSelector } from 'reselect';

import auth, { selectors as authSelectors } from './auth';
import feathers, { selectors as feathersSelectors } from './feathers';
import qui, { selectors as quiSelectors } from './qui';
import { reducer as form } from 'redux-form/immutable';

export default combineReducers({
  auth,
  feathers,
  qui,
  form
});

export const selectors = {
  auth: authSelectors,

  feathers: feathersSelectors,

  qui: quiSelectors,

  authenticated: createSelector(
    state => authSelectors.accessToken(state),
    accessToken => Boolean(accessToken)
  ),

  loaded: createSelector(
    state => authSelectors.loaded(state),
    authLoaded => authLoaded
  ),

  surveyed: createSelector(
    state => feathersSelectors.eulaStatus(state),
    state => feathersSelectors.educationLevel(state),
    state => feathersSelectors.sector(state),
    state => feathersSelectors.useCase(state),
    (eula, edu, sector, use) => Boolean(eula && edu && sector && use)
  ),

  saveLimitReached: createSelector(
    state => feathersSelectors.userId(state),
    state => feathersSelectors.userSaveLimit(state),
    state => feathersSelectors.circuits(state),
    (userId, saveLimit, circuits) => {
      if (!userId) return true;
      const circuitCount = circuits
        .toJS()
        .filter(circuit => circuit.userId === userId).length;
      return circuitCount >= saveLimit;
    }
  )
};

export { default as actions } from './actions';
export { default as saga } from './sagas';
