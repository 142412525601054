import { fromJS, getIn } from 'immutable';
import { handleActions } from 'redux-actions';

const defaultState = fromJS({
  userProfile: undefined,
  circuits: [],
  circuitsLoading: false,
  publicGroupId: null
});

export default handleActions(
  {
    FEATHERS: {
      RESET: state => state.merge(defaultState),

      SET_USER_PROFILE: (state, { payload }) => state.merge(fromJS(payload)),

      SET_CIRCUITS: (state, { payload }) => state.merge(fromJS(payload)),

      SET_CIRCUITS_LOADING: (state, { payload }) =>
        state.merge(fromJS(payload)),

      SET_PUBLIC_GROUP_ID: (state, { payload }) => state.merge(fromJS(payload))
    }
  },
  defaultState
);

export const selectors = (path => ({
  userProfile: state => getIn(state, path.concat(['userProfile'])),

  userId: state => getIn(state, path.concat(['userProfile', '_id']), null),

  userSaveLimit: state =>
    getIn(state, path.concat(['userProfile', 'limits', 'saveLimit'])),

  eulaStatus: state =>
    getIn(state, path.concat(['userProfile', 'profile', 'eula'])),

  educationLevel: state =>
    getIn(state, path.concat(['userProfile', 'profile', 'edu'])),

  sector: state => getIn(state, path.concat(['userProfile', 'profile', 'sec'])),

  useCase: state =>
    getIn(state, path.concat(['userProfile', 'profile', 'use'])),

  circuits: state => getIn(state, path.concat(['circuits'])),

  circuitsLoading: state => getIn(state, path.concat(['circuitsLoading'])),

  publicGroupId: state => getIn(state, path.concat(['publicGroupId']))
}))(['feathers']);

export { default as actions } from './actions';
export { default as saga } from './sagas';
